import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { DatabaseService } from 'src/app/shared/database.service';
import { Url } from 'src/app/shared/models';

@Component({
  selector: 'app-app-grid',

  templateUrl: './app-grid.component.html',
  styleUrls: ['./app-grid.component.scss'],
})
export class AppGridComponent {
  showUrlCreateModel: boolean = false;
  apps$: Observable<Url[] | null>;

  // let apps;
  // Type 'Observable<Url[] | null>' is not assignable to type 'Observable<Url[]>'.
  // Type 'Url[] | null' is not assignable to type 'Url[]'.
  constructor(private database: DatabaseService) {
    this.apps$ = database.getUrl();
    database.getUrl().subscribe((data) => {
      console.log(data);
    });
  }
}
