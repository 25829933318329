<nav class="bg-white border-gray-200 dark:bg-gray-900 shadow">
  <div
    class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4"
  >
    <a href="#  " class="flex items-center">
      <img
        src="../../../assets/logos/wraptron_main.png"
        class="h-8 mr-3"
        alt="Wraptron Logo"
      />
      <span
        class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white"
        >Start</span
      >
    </a>

    <div class="flex items-center md:order-2">
      <button
        type="button"
        class="flex mr-3 text-sm bg-gray-100 rounded-full md:mr-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
        id="user-menu-button"
        aria-expanded="false"
        data-dropdown-toggle="user-dropdown"
        data-dropdown-placement="bottom"
      >
        <span class="sr-only">Open user menu</span>
        <img
          class="w-5 h-5 rounded-full"
          src="../../../assets/icons/user.png"
          alt="user photo"
        />
      </button>
      <!-- Dropdown menu -->
      <div
        class="z-50 hidden my-4 text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600"
        id="user-dropdown"
      >
        <!-- <pre *ngIf="user$ as user">{{ user | async | json }}</pre> -->
        <div class="px-4 py-3" *ngIf="user$ | async as user">
          <span class="text-left block text-sm text-gray-900 dark:text-white">
            {{ user?.name }}
          </span>
          <span
            class="block text-sm text-gray-500 truncate dark:text-gray-400"
            >{{ user?.email }}</span
          >
        </div>
        <div
          *ngIf="user$ | async as user"
          class="px-4 py-3 block text-sm text-gray-500 truncate dark:text-gray-400"
        >
          {{ user?.id }}
        </div>

        <ul class="py-2" aria-labelledby="user-menu-button">
          <li>
            <a
              href="#"
              class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
              >Settings</a
            >
          </li>

          <li>
            <a
              href="#"
              class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
              (click)="onSignout()"
              >Sign out</a
            >
          </li>
        </ul>
      </div>
      <a routerLink="/" class="md:ml-4">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="9" height="9" rx="2" fill="#6B7280" />
          <rect x="11" width="9" height="9" rx="2" fill="#6B7280" />
          <rect y="11" width="9" height="9" rx="2" fill="#6B7280" />
          <rect x="11" y="11" width="9" height="9" rx="2" fill="#6B7280" />
        </svg>
      </a>
    </div>
  </div>
</nav>
