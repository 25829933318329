import { Component, inject } from '@angular/core';
import { initFlowbite } from 'flowbite';
import { Firestore, collectionData, collection } from '@angular/fire/firestore';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  firestore: Firestore = inject(Firestore);

  title = 'Aloka';
  ngOnInit(): void {
    initFlowbite();
  }
}
