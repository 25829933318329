import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppGridComponent } from './components/app-grid/app-grid.component';
import { AuthGuard } from './shared/auth.guard';
import { LoginComponent } from './pages/login/login.component';
import { BaseComponent } from './layouts/base/base.component';
import { SignupComponent } from './pages/signup/signup.component';
import { UrlCreateComponent } from './components/url/url-create/url-create.component';

export const routes: Routes = [
  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'signup',
    component: SignupComponent,
  },
  {
    path: 'url/new',
    component: UrlCreateComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
