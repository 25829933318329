export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyDqV3Nn9vQ4bXAidTOZ8hkwdE_1G6UJrfU',
    authDomain: 'wraptron-aloka.firebaseapp.com',
    projectId: 'wraptron-aloka',
    storageBucket: 'wraptron-aloka.appspot.com',
    messagingSenderId: '169864684859',
    appId: '1:169864684859:web:47027374f0f88497ee831e',
  },
};
