import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/shared/auth.service';
import { Router } from '@angular/router';
import { DatabaseService } from 'src/app/shared/database.service';
import { User } from 'src/app/shared/models';

@Component({
  selector: 'app-signup',
  // standalone: true,
  // imports: [CommonModule],
  templateUrl: './signup.component.html',
  styleUrl: './signup.component.scss',
})
export class SignupComponent {
  get name() {
    return this.signupForm.get('name');
  }
  get email() {
    return this.signupForm.get('email');
  }
  get password() {
    return this.signupForm.get('password');
  }
  get confirmPassword() {
    return this.signupForm.get('confirmPassword');
  }

  signupForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private database: DatabaseService,
    private router: Router
  ) {
    this.signupForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      name: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(6)]],
      isAgreed: ['', Validators.required],
    });
  }

  onSubmit() {
    this.auth
      .signup(this.email?.value, this.password?.value, this.signupForm.value)
      .then((u: any) => {
        const user: User = {
          name: this.name?.value,
          email: this.email?.value,
          id: u.uid,
        };
        this.database.addUserProfile(user);
        this.router.navigate(['/']);
      });
  }
}
