import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { initFlowbite } from 'flowbite';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/shared/auth.service';
import { DatabaseService } from 'src/app/shared/database.service';
import { User } from 'src/app/shared/models';

@Component({
  selector: 'top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss'],
})
export class TopNavComponent {
  // email;

  // u$: Observable<User>;
  // name;
  user$: Observable<User | null>;
  constructor(
    private auth: AuthService,
    private router: Router,
    db: DatabaseService
  ) {
    this.user$ = db.getUserProfile();
  }

  ngOnInit() {
    initFlowbite();
  }
  onSignout() {
    this.auth.signout().then(() => {
      this.router.navigate(['/login']);
    });
  }
}
