import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import {
  BrowserModule,
  provideClientHydration,
} from '@angular/platform-browser';

import { AppRoutingModule } from './app.routes';
import { AppComponent } from './app.component';
import { AppGridComponent } from './components/app-grid/app-grid.component';
import { TopNavComponent } from './components/top-nav/top-nav.component';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { environment } from './environments/environment.prod';
import { BaseComponent } from './layouts/base/base.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './pages/login/login.component';
import { SignupComponent } from './pages/signup/signup.component';
import { UrlCreateComponent } from './components/url/url-create/url-create.component';
import { CommonModule } from '@angular/common';

import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';

@NgModule({
  declarations: [
    AppComponent,
    AppGridComponent,
    TopNavComponent,
    BaseComponent,
    LoginComponent,
    SignupComponent,
  ],
  providers: [
    provideClientHydration(),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    UrlCreateComponent,
  ],
})
export class AppModule {}
