import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppModule } from 'src/app/app.module';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { DatabaseService } from 'src/app/shared/database.service';

@Component({
  selector: 'app-url-create',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: './url-create.component.html',
  styleUrl: './url-create.component.scss',
})
export class UrlCreateComponent {
  newUrlForm: FormGroup;

  constructor(private fb: FormBuilder, private db: DatabaseService) {
    this.newUrlForm = this.fb.group({
      url: ['', [Validators.required]],
      name: ['', [Validators.required]],
    });
  }

  onClick() {
    // this.auth.login(this.email?.value, this.password?.value).then(() => {
    //   this.router.navigate(['/']);
    // });
    this.db.addUrl(this.newUrlForm.value);
    console.log(this.newUrlForm.value);
  }
}
