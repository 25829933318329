import { Injectable, inject } from '@angular/core';

import {
  CollectionReference,
  DocumentReference,
  Firestore,
  addDoc,
  setDoc,
  doc,
  docData,
  collection,
  collectionData,
} from '@angular/fire/firestore';
import { Observable, map, switchMap } from 'rxjs';
import { Url, User } from './models';

import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class DatabaseService {
  private firestore: Firestore = inject(Firestore);
  user$!: Observable<User>;
  userDocument!: DocumentReference;
  urlCollection!: CollectionReference;

  constructor(private auth: AuthService) {
    auth.user$.subscribe((udata) => {
      this.userDocument = doc(this.firestore, `users/${udata?.uid}`);
      this.urlCollection = collection(
        this.firestore,
        `users/${udata?.uid}/url`
      );
      this.user$ = docData(this.userDocument) as Observable<User>;
    });
  }

  addUserProfile(meta: User) {
    if (!meta) return;
    setDoc(doc(this.firestore, `users/${meta.id}`), meta);
  }

  getUserProfile() {
    return this.auth.user$.pipe(
      switchMap((udata) => {
        if (!udata?.uid) {
          return Promise.resolve(null); // or any default value if uid is not available
        }
        this.userDocument = doc(this.firestore, `users/${udata.uid}`);
        return docData(this.userDocument) as Observable<User>;
      })
    );
  }

  // URL Create Model
  addUrl(url: any) {
    if (!url) return;
    addDoc(this.urlCollection, <Url>{ url });
  }

  getUrl() {
    return this.auth.user$.pipe(
      switchMap((udata) => {
        if (!udata?.uid) {
          return Promise.resolve(null); // or any default value if uid is not available
        }
        this.urlCollection = collection(
          this.firestore,
          `users/${udata?.uid}/url`
        );
        return collectionData(this.urlCollection) as Observable<Url[]>;
      })
    );
  }
}
