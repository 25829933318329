<div
  class="container mx-auto grid grid-cols-4 md:grid-cols-8 lg:grid-cols-10 xl:grid-cols-12 2xl:grid-cols-16 gap-4 p-4"
>
  <!-- App Icon 4 -->
  <a
    href="https://store.wraptron.com"
    class="bg-gray-200 rounded-lg p-4 text-center w-20 h-20"
  >
    <div class="w-8 h-8 mx-auto">
      <img src="assets/icons/store.png" alt="Aloka store" />
    </div>
    <div class="text-m font-semibold">Store</div>
  </a>

  <a
    href="https://people.zoho.in/wraptron/zp"
    class="bg-gray-200 rounded-lg p-4 text-center w-20 h-20"
  >
    <div class="w-8 h-8 mx-auto">
      <img src="assets/icons/people.png" alt="Zoho people" />
    </div>
    <div class="text-m font-semibold">People</div>
  </a>

  <a
    href="https://linear.app/wraptron/"
    class="rounded-lg p-4 text-center w-20 h-20"
    style="background-color: #5e6ad2"
  >
    <div class="w-8 h-8 mx-auto">
      <img src="assets/icons/linear.svg" alt="Linear app" />
    </div>
    <div class="text-m text-white font-semibold">Linear</div>
  </a>

  <!-- Add more app icons as needed -->
  <!-- display app icons with template from firebase -->
  <a
    *ngFor="let app of apps$ | async"
    href="{{ app.url.url }}"
    class="bg-gray-200 rounded-lg p-4 text-center w-20 h-20"
  >
    <!-- <a href="{{ app.url }}"> -->
    <!-- <div class="w-8 h-8 mx-auto"> -->
    <!-- <img [src]="app.icon" alt="App Icon" /> -->
    <!-- </div> -->
    <div class="text-m font-semibold">{{ app.url.name }}</div>
    <!-- </a> -->
  </a>

  <app-url-create></app-url-create>
</div>
